import http from "../http-common";

class FileUploadService {
  upload(apiKey, file, onUploadProgress) {
    let formData = new FormData();

    formData.append("file", file);

    return http.post("https://ocr.hksay.com/api/v3/process", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        "ocr-api-key": apiKey,
      },
      onUploadProgress,
    });
  }

  getResult(apiKey, token) {
    return http.get("https://ocr.hksay.com/api/v3/document-result/" + token, {
      headers: {
        "ocr-api-key": apiKey,
      },
    });
  }
}

export default new FileUploadService();
