import React, { Component } from "react";
import UploadService from "../services/file-upload.service";

export default class UploadImages extends Component {
  constructor(props) {
    super(props);
    this.selectFile = this.selectFile.bind(this);
    this.upload = this.upload.bind(this);
    this.parse = this.parse.bind(this);

    this.state = {
      currentFile: undefined,
      previewImage: undefined,
      progress: 0,
      message: "",
      imageInfos: [],
      token: undefined,
      res: null,
      apiKey: null,
    };
  }

  componentDidMount() {
    const queryParams = new URLSearchParams(window.location.search);
    this.setState({
      apiKey: queryParams.get("api-key"),
    });
  }

  selectFile(event) {
    this.setState({
      currentFile: event.target.files[0],
      previewImage: URL.createObjectURL(event.target.files[0]),
      progress: 0,
      message: "",
    });

    setTimeout(() => {
      this.upload();
    }, 1000);
  }

  parse() {
    const timer = setInterval(() => {
      UploadService.getResult(this.state.apiKey, this.state.token).then(
        (response) => {
          this.setState({
            res: response.data.data,
          });

          if (response.data.data) {
            clearTimeout(timer);
          }
        }
      );
    }, 3000);
  }

  upload() {
    this.setState({
      progress: 0,
      res: null,
      token: null,
    });

    UploadService.upload(this.state.apiKey, this.state.currentFile, (event) => {
      this.setState({
        progress: Math.round((100 * event.loaded) / event.total),
      });
    })
      .then((response) => {
        this.setState({
          message: response.data.data.token,
          token: response.data.data.token,
        });

        this.parse();
      })
      .catch((err) => {
        let message = "Fail to upload!";
        try {
          message = "fail reason: " + err.response.data.meta.message;
        } catch (e) {
          message += err.message;
        }

        this.setState({
          progress: 0,
          message: message,
          currentFile: undefined,
        });
      });
  }

  render() {
    const { currentFile, previewImage, progress, message, res, token, apiKey } =
      this.state;

    return (
      <div>
        {apiKey ? (
          <div className="row">
            <div className="col-8">
              <label className="btn btn-default p-0">
                <input
                  type="file"
                  accept="image/*"
                  onChange={this.selectFile}
                />
              </label>
            </div>

            <div className="col-4">
              <button
                className="btn btn-success btn-sm"
                disabled={!currentFile}
                onClick={this.upload}
              >
                Upload
              </button>
            </div>
          </div>
        ) : (
          "API key not found"
        )}

        {currentFile && (
          <div className="progress my-3">
            <div
              className="progress-bar progress-bar-info progress-bar-striped"
              role="progressbar"
              aria-valuenow={progress}
              aria-valuemin="0"
              aria-valuemax="100"
              style={{ width: progress + "%" }}
            >
              {progress}%
            </div>
          </div>
        )}

        {previewImage && (
          <div className="row">
            <div className="col-6">
              <img className="preview" src={previewImage} alt="" />
            </div>
            {token && (
              <div className="col-6">
                <div className="card-header">Result</div>
                <pre className="m-3">
                  {res ? JSON.stringify(res, null, 2) : "parsing..."}
                </pre>
              </div>
            )}
          </div>
        )}

        {message && (
          <div className="alert alert-secondary mt-3" role="alert">
            {message}
          </div>
        )}
      </div>
    );
  }
}
